import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { hotjar } from 'react-hotjar';
import {
  askForPermissionToRecieveNotifications,
  notificationHandler
} from './utils/firebase';

Sentry.init({
  dsn: "https://ba4ae37ef1cd465290d25cd424775100@sentry.caprover.greentagpro.com/4",
  autoSessionTracking: true,
  integrations: [
    new Integrations.BrowserTracing(),
  ],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

hotjar.initialize(1744098, 6);

ReactDOM.render(<App />, document.getElementById('wrapper'));

askForPermissionToRecieveNotifications();

notificationHandler();

if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .register(window.location.protocol + '//' + window.location.host + '/firebase-messaging-sw.js')
    .then(function (registration) {
      console.log("Registration successful, scope is: ", registration.scope);
    })
    .catch(function (err) {
      console.log("Service worker registration failed, error: ", err);
    });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
//serviceWorker.unregister();