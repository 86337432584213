import React, { useEffect, useState } from 'react';

import {
  Link,
  useRouteMatch,
  useHistory,
  useParams,
  useLocation,
} from 'react-router-dom';

import {
  Button,
  ButtonGroup,
  Card,
  FormGroup,
  Input,
  Label,
} from 'reactstrap';

import {
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';

import { 
  Formik,
  Form as FormikForm
} from 'formik';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import SidebarToggler from '../../../components/SidebarToggler';

import api from '../../../utils/api';

import moment from 'moment';

import qs from 'query-string';

import { useToasts } from 'react-toast-notifications';

const INITIAL_QUERY = {}

const INITIAL_PAGE = {
  page: 1,
  sizePerPage: 50,
  totalSize: 1,
  hideSizePerPage: true,
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Listing = (props) => {
  let { path } = useRouteMatch();
  let { type, subtype } = useParams();
  let history = useHistory();
  let queryString = useQuery();

  let [profile, setProfile] = useState({});
  const [permissions, setPermissions] = useState([]);
  const [data, setData] = useState([]);
  const [dataCsv, setDataCsv] = useState([]);

  const [query, setQuery] = useState(INITIAL_QUERY);
  const [pagingInfo, setPagingInfo] = useState(INITIAL_PAGE);
  const [refresh, setRefresh] = useState(false);
  let [token, setToken] = useState('');

  const { addToast } = useToasts();

  let columns = [
    {
      editable: false,
      text: "No",
      csvFormatter: (cell, row, rowIndex) => {
        let rowNumber = (pagingInfo.page - 1) * pagingInfo.sizePerPage + (rowIndex + 1);
         return rowNumber
       },
      formatter: (cell, row, rowIndex) => {
       let rowNumber = (pagingInfo.page - 1) * pagingInfo.sizePerPage + (rowIndex + 1);
        return <span>{rowNumber}.</span>;
      }
    },
    {
      dataField: 'date',
      text: 'Date',
      formatter: (cell, row , rowIndex) => {
        return row.date ? moment(row.date).format('DD/MM/YYYY') : ""
      },
      style: {
        whiteSpace: "pre-wrap"
      },
    }, 
    {
      
      text: 'Date Of Audit',
      csvFormatter: (cell, row , rowIndex) => {
        return row.date_of_audit_start && row.date_of_audit_end ? moment(row.date_of_audit_start).format('DD/MM/YYYY') + ' - ' + moment(row.date_of_audit_end).format('DD/MM/YYYY') : ""
      },
      formatter: (cell, row , rowIndex) => {
        return row.date_of_audit_start && row.date_of_audit_end ? moment(row.date_of_audit_start).format('DD/MM/YYYY') + ' - ' + moment(row.date_of_audit_end).format('DD/MM/YYYY') : ""
      },
      style: {
        whiteSpace: "pre-wrap"
      }
    }, 
    {
    dataField: 'stage',
    text: 'Type',
    style: {
      whiteSpace: "pre-wrap"
    },
    formatter: (cell, row, rowIndex) => {
      return row && row.stage_label ? row.stage_label : "";
    },
  }, {
    text: 'Organization',
    csvFormatter: (cell, row, rowIndex) => {
      if (row.client && row.client.name) {
          return row.client.name;
      }
  },
    formatter: (cell, row, rowIndex) => {
        if (row.client && row.client.name) {
            return row.client.name;
        }
    },
    style: {
      whiteSpace: "pre-wrap"
    },
  }, {
    dataField: 'client.certifications',
    text: "Cert(s) Required",
    csvFormatter: (cell, row, rowIndex) => {
      return(
        cell && cell.length > 0 &&
          cell.map((CERT, key) => 
              CERT.code  === 'MSPO' && subtype && subtype === 'part-2' ? CERT.code+' MS 2530-2:2013' :  subtype === 'part-3' ? CERT.code+' MS 2530-3:2013' : subtype === 'part-4' ? CERT.code+' MS 2530-4:2013' : subtype === 'mspo-sccs' ? 'MSPO-SCCS-01' : CERT.code 
          )
      )
    },
    formatter: (cell, row, rowIndex) => {
      return (
        <ul>
          {cell && cell.length > 0 && cell.map((CERT, key) => 
            <li>{CERT.code} {type.toUpperCase() === 'MSPO' && subtype ? <span className="text-muted">{subtype === 'part-2' ? 'MS 2530-2:2013' : ''}{subtype === 'part-3' ? 'MS 2530-3:2013' : ''}{subtype === 'part-4' ? 'MS 2530-4:2013' : ''}{subtype === 'mspo-sccs' ? 'MSPO-SCCS-01' : ''}</span> : ""}</li>
          )}
        </ul>
      )
    }
  }, {
    dataField: 'attendances',
    text: "Attendance",
    csvFormatter: (cell, row, rowIndex) => {
      let opening = '';
      let closing = '';
      if(cell && cell.length > 0){
        cell.filter(at=>at.opening!==null).map((ATT, key) => 
        key == 0 ? ATT.opening != null ?
        opening = moment(ATT.opening).format('DD/MM/YY'): '' : ''
        );
          cell.filter(at=>at.closing!==null).map((ATT, key) => 
         key == 0 ? ATT.closing != null ?
         closing = moment(ATT.closing).format('DD/MM/YY'): '' : ''
         );
        
         return('Opening : '+opening+' Closing : '+closing)
      } 
        
    },
    formatter: (cell, row, rowIndex) => {
     let opening = '';
     let closing = '';
      
        if(cell && cell.length > 0){
          cell.filter(at=>at.opening!==null).map((ATT, key) => 
          key == 0 ? ATT.opening != null ?
          opening = moment(ATT.opening).format('DD/MM/YY'): '' : ''
          );
            cell.filter(at=>at.closing!==null).map((ATT, key) => 
           key == 0 ? ATT.closing != null ?
           closing = moment(ATT.closing).format('DD/MM/YY'): '' : ''
           );
          
           return('Opening : '+opening+' \n Closing : '+closing)
        } 

    },
    
  }, {
    dataField: 'id',
    text: ' ',
    csvExport: false,
    formatter: (cell, row, rowIndex) => {
      return (
        <ButtonGroup size="sm">
          {/* <Button 
            outline
            onClick={() => {
              window.open(`${process.env.REACT_APP_API_URL}/audits/view-certificate/${row.id}?export=certificate&token=${token}&signature=2`);
            }}
          >
            <i className="icon-eye"></i>
          </Button> */}

          <Button outline tag={Link} to={`${path.replace(/:type/, type).replace(/:subtype/, subtype)}/view/${cell}`} >
            <i className="icon-eye"></i>
          </Button>
          {permissions && permissions.find(o => o.name.toLowerCase() === 'audit_plan.update') ?
          <Button outline tag={Link} to={`${path.replace(/:type/, type).replace(/:subtype/, subtype)}/edit/${cell}`}>
            <i className="icon-pencil"></i>
          </Button>
          : null}
          {permissions && permissions.find(o => o.name.toLowerCase() === 'audit_plan.delete') ?
          <Button outline color="danger" onClick={() => deleteHandler(cell)}>
            <i className="icon-trash"></i>
          </Button>
          : null}
        </ButtonGroup>
      )
    },
    style: { textAlign: 'center' }
  }];

  useEffect(() => {
    const getToken = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      setToken(token.access_token);
    }

    getToken();

    const getMe = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      await api.post('/auth/me')
      .then(function (response) {
        // localStorage.setItem('auth-user', JSON.stringify(response.data));
        
        setProfile(response.data);
      })
      .catch(function (error) {
        // if (error && error.response && error.response.data) {
        //   if (error.response.data.message === 'Unauthenticated.') {
        //     localStorage.removeItem('auth-token');
        //     localStorage.removeItem('auth-user');
        //     setAuthState('');
        //   }
        // } else {
        //   console.log(error.message);
        // }
      });
    }

    if (!profile.permissions) {
      getMe();
    }

    // console.log(type);
    const getData = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;
      //api.get('/audit-plans?include_audits=true&type=' + type + '&subtype=' + subtype + '&=' + Math.random().toString(36).substring(7) + '&year=' + queryString.get('year'), {
      api.get('/audit-plans?type=' + type + '&subtype=' + subtype + '&=' + Math.random().toString(36).substring(7) + '&year=' + queryString.get('year'), {
        params: query,
      })
      .then(function (response) {
         console.log(response);

        setPermissions(response.data.meta.permissions);
        setData(response.data.data);

        setPagingInfo(pagingInfo => ({
          ...pagingInfo,
          page: response.data.meta.current_page,
          sizePerPage: response.data.meta.per_page,
          totalSize: response.data.meta.total,
        }));
      })
      .catch(function (error) {
        // console.log(error);
        
        if (error && error.response && error.response.data) {
          addToast(error.response.data.message, { appearance: 'error' });
        } else {
          addToast(error.message, { appearance: 'error' });
        }
      });

      setRefresh(false);
    }

    getData();

    const getDataCsv = async () => {
      let token = await localStorage.getItem('auth-token');
  
      token = JSON.parse(token);
  
      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;
      //api.get('/audit-plans?include_audits=true&type=' + type + '&subtype=' + subtype + '&=' + Math.random().toString(36).substring(7) + '&year=' + queryString.get('year')+'&limit=-1', {
        api.get('/audit-plans?type=' + type + '&subtype=' + subtype + '&=' + Math.random().toString(36).substring(7) + '&year=' + queryString.get('year')+'&limit=-1', {
        params: query,
      })
      .then(function (response) {
        setPermissions(response.data.meta.permissions);
        setDataCsv(response.data.data);
      })
      .catch(function (error) {
        // console.log(error);
        
        if (error && error.response && error.response.data) {
          addToast(error.response.data.message, { appearance: 'error' });
        } else {
          addToast(error.message, { appearance: 'error' });
        }
      });
  
      setRefresh(false);
    }
  
    getDataCsv();
  }, [props, type, profile, query]);

  const MyExportCSV = (props) => {
    const handleClick = () => {
     
      // passing my custom data
      props.onExport(dataCsv);
    };
    return (
      
        <OverlayTrigger
          placement="top"
          overlay={
          <Tooltip id={`tooltip-1`}>
            Export to CSV
          </Tooltip>
          }
        >
           <Button className="btn btn-success"  onClick={ handleClick }>
              <i className="fa fa-file-excel-o"></i>
            </Button>
            </OverlayTrigger>
    );
  };
  const deleteHandler = async (id) => {
    if (window.confirm('Delete this record?')) {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      api.delete('/audit-plans/' + id)
      .then(function (response) {
        // console.log(response);

        // setData(response.data.data);

        addToast('Audit plan successfully deleted', { appearance: 'success' });

        history.push(`${path.replace(/:type/, type).replace(/:subtype/, subtype)}`);

        // setRefresh(true);
      })
      .catch(function (error) {
        // console.log(error.response);
        addToast(error.response.data.message, { appearance: 'error' });
      });
    }
  }

  const handleTableChange = (type, { page, sizePerPage }) => {
    setQuery(query => ({
      ...query, 
      page: page,
    }));

    setPagingInfo(pagingInfo => ({
      ...pagingInfo,
      page: page,
      sizePerPage: sizePerPage,
    }));
  }

  return (
    <ToolkitProvider
  keyField="id"
  data={data}
  columns={ columns }
  exportCSV
>{
    props => (
    <React.Fragment>
      <div className="container-fluid">
        <div className="block-header">
          <div className="row">
            <div className="col-lg-6 col-md-8 col-sm-12">
              <h2 style={{ textTransform: "capitalize" }}>
                <SidebarToggler />
                {type.toUpperCase()}{type.toUpperCase() === 'MSPO' && subtype ? ` ${subtype.replace(/-/, ' ')}` : ""} Audit Plan
              </h2>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <i className="icon-home"></i>
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  Audit
                </li>
                <li className="breadcrumb-item">
                  Audit Plan
                </li>
                <li className="breadcrumb-item" style={{ textTransform: "capitalize" }}>
                  <Link to={path.replace(/:type/, type).replace(/:subtype/, subtype)}>
                    {type.toUpperCase()}{type.toUpperCase() === 'MSPO' && subtype ? ` ${subtype.replace(/-/, ' ')}` : ""}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row clearfix">
          <div className="col-lg-12 col-md-12">
            <Card>
              <div className="header">
                <h2>AUDIT PLAN LIST</h2>
                {profile.permissions && profile.permissions.find(o => o.name.toLowerCase() === 'audit_plan.create') ?
                <ul className="header-dropdown">
                  <li>
                    <Button 
                      className="m-t-10 m-l-20"
                      color="primary" 
                      tag={Link} 
                      to={`${path.replace(/:type/, type).replace(/:subtype/, subtype)}/create`}
                    >
                      Add New
                    </Button>
                  </li>
                </ul>
                : null}
              </div>
              <div className="body">
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    client_name: queryString.get('client_name') ? queryString.get('client_name') : "",
                    start: queryString.get('start') ? queryString.get('start') : "",
                    end: queryString.get('end') ? queryString.get('end') : "",
                    // region: queryString.get('region') ? queryString.get('region') : "",
                    // state: queryString.get('state') ? queryString.get('state') : "",
                    // certifications: queryString.get('certifications') ? queryString.get('certifications') : "",
                    // status: queryString.get('status') ? queryString.get('status') : "",
                  }}
                  onSubmit={async (values, { setSubmitting }) => {
                    var searchString = qs.stringify(values); // objectToFormData(values, {indices: true, booleansAsIntegers: true});

                    setQuery(values);

                    history.push({
                      pathname: path.replace(/:type/, type).replace(/:subtype/, subtype),
                      search: searchString
                    });

                    setQuery(values);
                  }}
                >
                  {({ isSubmitting, values, setFieldValue, handleChange }) => (
                  <FormikForm>
                    <FormGroup>
                      <Label for="Name">Organization</Label>
                      <Input 
                        type="text" 
                        // className="custom-select" 
                        name="client_name" 
                        id="Name"
                        onChange={handleChange}
                        value={values.client_name}
                      />
                    </FormGroup>
                    <FormGroup>
                        <Label for="Date">Date of Audit</Label>
                        <DateRangePicker 
                         // disabled={readOnly}
                          initialSettings={{
                            timePicker: false
                          }}
                          timePicker={false}
                          timePickerSeconds={false}
                          containerStyles={{ display: "block" }}
                          start={values.start && values.start.length ? moment(values.start).format('MM/DD/YYYY') : moment().format('MM/DD/YYYY')}
                          end={values.end && values.start.length ? moment(values.end).format('MM/DD/YYYY') : moment().format('MM/DD/YYYY')}
                          onApply={(event, picker) => { 
                            setFieldValue('start', picker.startDate.format('YYYY-MM-DD'));
                            setFieldValue('end', picker.endDate.format('YYYY-MM-DD'));
                          }}
                        >
                          <Input 
                            autoComplete="off"
                          //  readOnly={readOnly}
                            type="text" 
                            // name="date_of_audit" 
                            id="date"
                            // onChange={handleChange}
                            value={values.start && values.end ? moment(values.start).format('DD/MM/YYYY') + ' - ' + moment(values.end).format('DD/MM/YYYY') : ""}
                          />
                        </DateRangePicker>
                      </FormGroup>
                    <div className="row clearfix">
                      <div className="col-sm-4">
                        <Label>&nbsp;</Label>
                        <FormGroup>
                          <Button 
                            // className="btn btn-outline-secondary"
                            outline
                            color="secondary" 
                            // tag={Link} 
                            // to="/client-database"
                          >
                            Search
                          </Button>
                          <Button 
                            // className="btn btn-outline-secondary"
                            type="button"
                            outline
                            color="link" 
                            // tag={Link} 
                            // to={`${path}`}
                            onClick={() => {
                              setQuery(INITIAL_QUERY);

                              history.push({
                                pathname: path.replace(/:type/, type).replace(/:subtype/, subtype),
                              });

                              setQuery(INITIAL_QUERY);
                            }}
                          >
                            Reset
                          </Button>
                        </FormGroup>
                      </div>
                    </div>
                  </FormikForm>
                  )}
                </Formik>

                <hr />
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 text-right"><MyExportCSV { ...props.csvProps } />
              </div>
              <div className="body project_report">
                <div className="table">
                  <BootstrapTable  { ...props.baseProps } 
                    remote={true}
                    bootstrap4
                    bordered={false}
                    classes="table-hover js-basic-example dataTable table-custom m-b-0"
                    headerClasses="thead-dark"
                    keyField="id"
                    data={ data } 
                    columns={ columns }
                    pagination={ 
                      paginationFactory(pagingInfo)
                    }
                    onTableChange={handleTableChange}
                  />
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
</ToolkitProvider>
)
}

export default Listing;