import React, { useEffect, useState } from 'react';

import {
  Link,
  useRouteMatch,
  useHistory,
  useLocation,
} from 'react-router-dom';

import {
  Card,
} from 'reactstrap';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

import SidebarToggler from '../../components/SidebarToggler';

import { useToasts } from 'react-toast-notifications';

import api from '../../utils/api';

import qs from 'query-string';

import moment from 'moment';

const INITIAL_QUERY = {}

const INITIAL_PAGE = {
  page: 1,
  sizePerPage: 50,
  totalSize: 1,
  hideSizePerPage: true,
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Listing = (props) => {
  const { addToast } = useToasts();

  const [data, setData] = useState([]);
  const [query, setQuery] = useState(INITIAL_QUERY);
  const [pagingInfo, setPagingInfo] = useState(INITIAL_PAGE);

  let queryString = useQuery();

  const columns = [
    {
      dataField: 'audit.audit_plan.client.name',
      text: 'Company/Organization',
      style: {
        whiteSpace: 'pre-wrap',
      },
    },
    {
      dataField: 'no',
      text: 'NCR No.',
      style: {
        whiteSpace: 'pre-wrap',
      },
    },
    {
      dataField: 'clause',
      text: 'Clause',
      style: {
        whiteSpace: 'break-word',
      },
    },
    {
      dataField: 'grade',
      text: 'Grade',
      style: {
        whiteSpace: 'pre-wrap',
      },
      formatter: (cell, row, rowIndex) => {
        return (
          <span className={cell === 'Major' ? 'badge badge-danger' : 'badge badge-warning'}>
            {cell}
          </span>
        );
      }
    },
    {
      dataField: 'ncr_close',
      text: 'Status',
      style: {
        whiteSpace: 'pre-wrap',
      },
      formatter: (cell, row, rowIndex) => {
        return (
          <span className={cell === 'Y' ? 'badge badge-success' : 'badge badge-danger'}>
            {cell === 'Y' ? 'Closed' : 'Open'}
          </span>
        );
      }
    },
    {
      dataField: 'issued_date',
      text: 'Issued Date',
      style: {
        whiteSpace: 'no-wrap',
      },
      formatter: (cell, row, rowIndex) => {
        if (cell && cell.length) {
          return moment(cell).format('DD/MM/YYYY');
        }
      }
    },
    {
      dataField: 'closed_date',
      text: 'Closed Date',
      style: {
        whiteSpace: 'pre-wrap',
      },
      formatter: (cell, row, rowIndex) => {
        if (cell && cell.length) {
          return moment(cell).format('DD/MM/YYYY');
        }
      }
    }
  ];

  const handleTableChange = (type, { page, sizePerPage }) => {
    setQuery(query => ({
      ...query, 
      page: page,
    }));

    setPagingInfo(pagingInfo => ({
      ...pagingInfo,
      page: page,
      sizePerPage: sizePerPage,
    }));
  }

  useEffect(() => {
    const getData = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      await api.get('/non-conformances?' + queryString.toString(), {
        params: query,
      })
      .then(function (response) {
        // console.log(response);
        setData(response.data.data);

        setPagingInfo(pagingInfo => ({
          ...pagingInfo,
          page: response.data.meta.current_page,
          sizePerPage: response.data.meta.per_page,
          totalSize: response.data.meta.total,
        }));
      })
      .catch(function (error) {
        // console.log(error);
        
        if (error && error.response && error.response.data) {
          addToast(error.response.data.message, { appearance: 'error' });
        } else {
          addToast(error.message, { appearance: 'error' });
        }
      });
    };

    getData();
  }, [props, query]);

  return (
    <div className="container-fluid">
      <div className="block-header">
        <div className="row">
          <div className="col-lg-6 col-md-8 col-sm-12">
            <h2>
              <SidebarToggler />
              Non Conformance
            </h2>
            <ul className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">
                  <i className="icon-home"></i>
                </Link>
              </li>
              <li className="breadcrumb-item active">
                <Link to="/">
                  Non Conformance
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="row clearfix">
        <div className="col-lg-12 col-md-12">
          <Card>
            <div className="header">
              <h2>NON CONFORMANCE LIST</h2>
            </div>

            <div className="body project_report">
              <div className="table">
                <BootstrapTable 
                  bootstrap4
                  remote={true}
                  bordered={false}
                  classes="table-hover js-basic-example dataTable table-custom m-b-0"
                  headerClasses="thead-dark"
                  keyField='id' 
                  data={ data } 
                  columns={ columns }
                  pagination={ 
                    paginationFactory(pagingInfo)
                  }
                  onTableChange={handleTableChange}
                />
              </div>
            </div>
          </Card>
        </div>
      </div>
    </div>
  )
}

export default Listing;