import React, { useEffect, useState } from "react";

import {
  Link
} from "react-router-dom";

import {
  Button,
  Spinner,
} from "reactstrap";

import SidebarToggler from "../../components/SidebarToggler";

import FullCalendar from '@fullcalendar/react';
import listPlugin from '@fullcalendar/list';

import api from '../../utils/api';

import moment from 'moment';

const monthNames = [
  "January", 
  "February", 
  "March", 
  "April", 
  "May", 
  "June",
  "July", 
  "August", 
  "September", 
  "October", 
  "November", 
  "December",
];

let audit_plan_type = '';
let audit_plan_subtype = '';

const NCSBDashboard = () => {
  const [data, setData] = useState({});

  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const element = document.querySelector("body");
    
    element.classList.add('page-calendar');

    const getData = async () => {
      // setLoading(true);

      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      await api.get('/dashboard')
      .then(function (response) {
        setData(response.data.data);
        
        audit_plan_type = response.data.data && response.data.data.audit && response.data.data.audit.audit_plan ? response.data.data.audit.audit_plan.type : 'iso';
        
        audit_plan_subtype = response.data.data && response.data.data.audit && response.data.data.audit.audit_plan ? response.data.data.audit.audit_plan.subtype : 'iso';

        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
      });
    }

    getData();
  }, []);

  return (
    <div className="container-fluid">
      <div className="block-header">
        <div className="row">
          <div className="col-lg-6 col-md-8 col-sm-12">
            <h2>
              <SidebarToggler />
              Dashboard
            </h2>
            <ul className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">
                  <i className="icon-home"></i>
                </Link>
              </li>
              <li className="breadcrumb-item">Dashboard</li>
              {/* <li className="breadcrumb-item active">Departments List</li> */}
            </ul>
          </div>
        </div>
      </div>

      {isLoading ?
      <div className="row clearfix">
        <div className="col-sm-12" style={{ textAlign: "center" }}>
          <Spinner size="lg" />
        </div>
      </div>
      :
      <React.Fragment>
        <div className="row clearfix">
          <div className="col-lg-3 col-md-3 col-sm-3">
            <Link to="/non-conformances?status=closed">
              <div className="card text-center bg-success">
                <div className="body">
                  <div className="p-15 text-light">
                    <h3>{data.ncr && data.ncr.closed ? data.ncr.closed : '0'}</h3>
                    <span>Closed NCR</span>
                  </div>
                </div>
              </div>
            </Link>
          </div>

          <div className="col-lg-3 col-md-3 col-sm-3">
            <Link to="/non-conformances?status=open">
              <div className="card text-center bg-warning">
                <div className="body">
                  <div className="p-15 text-light">
                    <h3>{data.ncr && data.ncr.total ? data.ncr.total : '0'}</h3>
                    <span>Pending NCR</span>
                  </div>
                </div>
              </div>
            </Link>
          </div>

          <div className="col-lg-3 col-md-3 col-sm-3">
            <Link to="/non-conformances?status=open&grade=major">
              <div className="card text-center bg-danger">
                <div className="body">
                  <div className="p-15 text-light">
                    <h3>{data.ncr && data.ncr.major ? data.ncr.major : '0'}</h3>
                    <span>Major NCR</span>
                  </div>
                </div>
              </div>
            </Link>
          </div>

          <div className="col-lg-3 col-md-3 col-sm-3">
            <Link to="/non-conformances?status=open&grade=minor">
              <div className="card text-center bg-info">
                <div className="body">
                  <div className="p-15 text-light">
                    <h3>{data.ncr && data.ncr.minor ? data.ncr.minor : '0'}</h3>
                    <span>Minor NCR</span>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>

        <div className="row clearfix">
          <div className="col-lg-6 col-md-12 col-sm-12">
            <div className="card">
              <div className="header">
                <h2>Audit &amp; Revenue Summary</h2>
              </div>
              <div className="body">
                <table className="table m-b-0 table-hover">
                  <thead className="thead-dark">
                    <tr>
                      <th>Month</th>
                      <th style={{ textAlign: "center" }}>Total</th>
                      <th style={{ textAlign: "center" }}>Revenue</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.audits && data.audits.monthly ? data.audits.monthly.map((audit, i) => 
                    <tr>
                      <td>{monthNames[i]}</td>
                      <td style={{ textAlign: "center" }}>{audit.total}</td>
                      <td style={{ textAlign: "center" }}>{audit.income}</td>
                    </tr>
                    ) : null}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-md-12 col-sm-12">
            <div className="card">
              <div className="header">
                <h2>Audit Report &amp; Review Status</h2>
              </div>
              <div className="body" style={{ minHeight: "640px" }}>
                <table className="table m-b-0 table-hover">
                  <thead className="thead-dark">
                    <tr>
                      <th>Date</th>
                      <th>Client</th>
                      <th>Stage</th>
                      <th>Status</th>
                      <th>&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.audits && data.audits.latest ? data.audits.latest.map((audit, i) => 
                    <tr>
                      <td style={{ whiteSpace: "pre-wrap" }}  >
                        {audit.date_of_audit_start && audit.date_of_audit_end ? moment(audit.date_of_audit_start).format('DD/MM') + ' - ' + moment(audit.date_of_audit_end).format('DD/MM') : ""}
                      </td>
                      <td style={{ whiteSpace: "pre-wrap" }}>{audit.name}</td>
                      <td style={{ whiteSpace: "pre-wrap" }}>{audit.stage}</td>
                      <td style={{ whiteSpace: "pre-wrap" }}>{audit.status}</td>
                      <td>
                        <Button 
                          outline 
                          size="sm" 
                          tag={Link} 
                          to={`/audit-module/audit-report/${audit_plan_type}/${audit_plan_subtype}/view/${audit.id}`}
                        >
                          <i className="icon-eye"></i>
                        </Button>
                      </td>
                    </tr>
                    ) : null}
                    <tr>
                      <td colSpan={5}>
                        <Button 
                          block
                          tag={Link} 
                          to="/audit-module/audit-report"
                        >
                          view more&hellip;
                        </Button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="row clearfix">

          <div className="col-lg-6 col-md-12 col-sm-12">
            <div className="card">
              <div className="header">
                <h2>Mandays Calculations</h2>
              </div>
              <div className="body"></div>
            </div>
          </div>

          <div className="col-lg-6 col-md-12 col-sm-12">
            <div className="card">
              <div className="header">
                <h2>Revenue</h2>
              </div>
              <div className="body"></div>
            </div>
          </div>
        </div> */}
      </React.Fragment>
      }
    </div>
  )
}

export default NCSBDashboard;