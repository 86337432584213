import React, { useState, useEffect } from "react";

import {
  Link,
  useHistory,
  useParams,
} from "react-router-dom";

import {
  Button,
  Card,
  FormGroup,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Spinner,
  TabContent,
  TabPane,
} from "reactstrap";

import { 
  Formik,
  Form as FormikForm
} from "formik";

import classnames from 'classnames';
//import FormMSPO from './FormMSPO';

//import moment from 'moment';

import moment from 'moment-timezone';

import DateRangePicker from 'react-bootstrap-daterangepicker';

import { Editor } from '@tinymce/tinymce-react';

import { useToasts } from 'react-toast-notifications';

import api from "../../../utils/api";

import SidebarToggler from "../../../components/SidebarToggler";

import { objectToFormData } from 'object-to-formdata';

const Form = (props) => {
  let { id, type, client_id } = useParams();

  let history = useHistory();

  let [profile, setProfile] = useState({});

  let [token, setToken] = useState('');

  const { addToast } = useToasts();


  const [data, setData] = useState({});
  const [auditors, setAuditor] = useState([]);
  const [reference, setReference] = useState([]);
  const [USERS, setUser] = useState([{}]);

  let { view, approval, review }  = props;

  const [activeTab, setActiveTab] = useState('1');

  const [readOnly, setReadOnly] = useState(false);

  const [review2, setReview2] = useState(false);
  //const sleep = (ms) => new Promise((r) => setTimeout(r, ms));
  const toggle = tab => {

    if(activeTab !== tab) setActiveTab(tab);
  }
  useEffect(() => {
    const getMe = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      setToken(token.access_token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      await api.post('/auth/me')
      .then(function (response) {
        // localStorage.setItem('auth-user', JSON.stringify(response.data));
         setProfile(response.data);

        /*  if (!readOnly && response.data.permissions && typeof response.data.permissions.find(o => o.name.toLowerCase() === 'technical_review.update') === "undefined") {
           history.push(props.path.replace(/:type/, type)  + '?refresh=' + Date.now());
          }
          if (!readOnly && response.data.permissions && typeof response.data.permissions.find(o => o.name.toLowerCase() === 'technical_review.approve') === "undefined") {
            history.push(props.path.replace(/:type/, type)  + '?refresh=' + Date.now());
           } */
      })
      .catch(function (error) {
        // if (error && error.response && error.response.data) {
        //   if (error.response.data.message === 'Unauthenticated.') {
        //     localStorage.removeItem('auth-token');
        //     localStorage.removeItem('auth-user');
        //     setAuthState('');
        //   }
        // } else {
        //   console.log(error.message);
        // }
      });
    }

    getMe();
    
    const getAuditors = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      api.get('/auditors?fields[]=id&fields[]=full_name&fields[]=business_mobile&fields[]=private_mobile&withUser=true&limit=-1')
      .then(function (response) {
         console.log(response.data);
        
        let auditors = [];
        response.data.data.map((auditor, i) => auditors.push({
          label: auditor.full_name,
          value: auditor.id,
          contact_no: auditor.contact_no,
        }));

        setAuditor(auditors);

        // console.log(auditors);
      })
      .catch(function (error) {
        // console.log(error.response);
        if (error && error.response && error.response.data) {
          addToast(error.response.data.message, { appearance: 'error' });
        } else {
          addToast(error.message, { appearance: 'error' });
        }
      });
    }

    getAuditors();

    const getUsers = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      api.get('/users?limit=-1&role[]=4&role[]=5')
      .then(function (response) {
        //console.log(response.data.data);
        let USERS = [];
        response.data.data.map((user, i) => USERS.push({
          label: user.name,
          value: user.id
        }));
        setUser(USERS);
      })
      .catch(function (error) {
        // console.log(error.response);
        if (error && error.response && error.response.data) {
          addToast(error.response.data.message, { appearance: 'error' });
        } else {
          addToast(error.message, { appearance: 'error' });
        }
      });
    }

    getUsers();

    if (id) {
      const getData = async () => {
        let token = await localStorage.getItem('auth-token');

        token = JSON.parse(token);

        api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

        api.get('/operation-reviews/' + id)
        .then(function (response) {
          console.log(response.data);
          delete response.data.data.id;

          if (response.data.data.audit && response.data.data.audit.audit_plan && response.data.data.audit.audit_plan.client && response.data.data.audit.audit_plan.client.nace_codes ) {
            let selectedCodes = [];
            response.data.data.audit.audit_plan.client.nace_codes.map((nace_code, key) => {
              return selectedCodes.push(nace_code.code);
            });

            response.data.data.audit.audit_plan.client.nace_codes = selectedCodes;
          }
          if (response.data.data.audit && response.data.data.audit.audit_plan && response.data.data.audit.audit_plan.client && response.data.data.audit.audit_plan.client.certifications ) {
            let cert = [];
            response.data.data.audit.audit_plan.client.certifications.map((certification, key) => {
              return cert.push(certification.standard);
            });

            response.data.data.audit.audit_plan.client.certifications.standard = cert;
          }

          if(view || approval /* || review */){
            setReadOnly(true);
          }
          if(response.data.meta.user.auditor_id !== response.data.data.technical_review_reviewer_1_id){
            setReadOnly(true);
          }     

          /* console.log(response.data.data.technical_review_reviewer_1_id +'=='+ profile.id)
          if(review && response.data.data.technical_review_reviewer_1_id != profile.id){
            setReadOnly(true);
          } */
          setData(response.data.data);

          // reload(false);
        })
        .catch(function (error) {
          if (error && error.response && error.response.data) {
            addToast(error.response.data.message, { appearance: 'error' });
          } else {
            addToast(error.message, { appearance: 'error' });
          }
        });
      }

      getData();
      if(review && data.technical_review_reviewer_1_id != profile.id) { setReadOnly(true); }

    }
    const getReferences = async () => {
     
      console.log(JSON. stringify(data));
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;
      api.get('/operation-reviews?client[]='+client_id)
      .then(function (response) {
         console.log(response.data.data);
         let references = [];
         response.data.data.map((reference, i) => references.push({
           label: reference.audit.audit_plan.stage,
           value: reference.id,
         }));
 
         setReference(references);
       /* let references = [];
        response.data.data.map((refer, i) => references.push({
          if(response.data.audit.audit_plan && refer.audit.audit_plan.stage){
       
            if(refer.audit.audit_plan.other_audit)
              return refer.audit.audit_plan.other_audit+'\n'+refer.audit.audit_plan.stage;
            else
              return refer.audit.audit_plan.stage;
          }
          label: auditor.full_name,
          value: refer.id,
          contact_no: auditor.contact_no,
        }));  */

       // setReference(references);

        // console.log(auditors);
      })
      .catch(function (error) {
        // console.log(error.response);
        if (error && error.response && error.response.data) {
          addToast(error.response.data.message, { appearance: 'error' });
        } else {
          addToast(error.message, { appearance: 'error' });
        }
      });
    }

    getReferences();
  }, [id]);
  
  //if (type.toString().toLowerCase() === 'mspo') {
 ///   return <FormMSPO path={props.path} type={type} {...props} />;
 // }
  return (
    
    <React.Fragment>
      <div className="container-fluid">
        <div className="block-header">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <h2>
                <SidebarToggler />
                {type.toUpperCase()} Certification Approval Panel (CAP)
              </h2>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <i className="icon-home"></i>
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  Review
                </li>
                <li className="breadcrumb-item">
                  Certification Approval Panel (CAP)
                </li>
                <li className="breadcrumb-item">
                  <Link to={props.path.replace(/:type/, type)}>
                    {type.toUpperCase()}
                  </Link>
                </li>
              </ul>
            </div>
            
            <div className="col-lg-6 col-md-6 col-sm-12 text-right">
              {data.technical_review_state=="6" ? 
              <button 
              className="m-r-20 btn btn-outline-secondary" 
              style={{ textDecoration: "none" }}
              type="button"
              onClick={() => {
                window.open(`/audit-module/audit-report/${data.audit.audit_plan.type}/${data.audit.audit_plan.subtype}/view-draft-certificate/${data.audit.id}`);
              }}
            >
              <i className="icon-badge"></i> View Certificate
            </button>
              : null}
              &nbsp;
              <Input 
                outline
                color="primary" 
                onChange={(e) => {
                  let id = e.currentTarget.options[e.currentTarget.selectedIndex].value;
                  window.open(`/boss-chart/view/`+id);
                }}
                type="select" 
                className="custom-select" 
                name="reference"
                id="opr_id"
                style={{ width: "3cm" }}
              >
                <option>Reference</option>
                {reference && reference.length ? reference.map((refer, i) => 
                <option value={refer.value}>{refer.label.toUpperCase()}</option>
              ) : ""}
              </Input>
             {/*  <Button 
                outline
                color="primary" 
                onClick={() => {
                  window.open(`/boss-chart/view/`+id);
                }}>
                <i className="icon-graph"></i> Reference 
              </Button>  */}&nbsp;
              {data.previous_audit_report !== null ? 
              <Button outline
                color="primary"
                target="_blank"  
                href={data.previous_audit_report && data.previous_audit_report.url ? data.previous_audit_report.url : null}
              >
              <i className="icon-paper-clip"></i> Prev. Audit Report
              </Button>
                : 
                null                
                }               
            </div>
          </div>
        </div>      
          <div className="row clearfix">
            <div className="col-lg-12 col-md-12">
            <Card>
              <div className="header">
                <h2>Certification Approval Panel (CAP)</h2>
              </div> 
              <div className="body">
                <div className="row clearfix">
                  <div className="col-sm-12">
                    <FormGroup>
                      <Label for="ToBeReviewedBy">Panel 1</Label>
                      <Input 
                        // readOnly={readOnly}
                        disabled={true}
                        type="select" 
                        className="custom-select" 
                        name="technical_review_reviewer_1_id"
                        id="ToBeReviewedBy"
                        value={data.technical_review_reviewer_1_id}
                        required={true}
                      >
                        <option></option>
                        {auditors && auditors.length ? auditors.map((auditor, i) => 
                        <option value={auditor.value}>{auditor.label.toUpperCase()}</option>
                      ) : ""}
                      </Input>
                    </FormGroup>
                  </div>
                   <div className="col-sm-12">
                  <FormGroup>
                    <Label for="ToBeReviewedBy">Panel 2</Label>
                    <Input 
                      // readOnly={readOnly}
                      disabled={true}
                      type="select" 
                      className="custom-select" 
                      name="technical_review_reviewer_2_id"
                      id="ToBeReviewedBy"
                      value={data.technical_review_reviewer_2_id}
                      required={true}
                    >
                      <option></option>
                      {USERS && USERS.length ? USERS.map((USER, i) => 
                      <option value={USER.value}>{USER.label}</option>
                    ) : ""}
                    </Input>
                  </FormGroup>
                </div>
                
                </div>
                <FormGroup>
                  <Label>Organization Name</Label>
                  
                  <Input 
                    readOnly={true}
                    type="text" 
                    name="client_name" 
                    id="ClientName"
                    value={data.audit && data.audit.audit_plan && data.audit.audit_plan.client ? data.audit.audit_plan.client.name : ''}
                  />
                </FormGroup>

                <FormGroup>
                  <Label>Type Of Audit</Label>
                  <Input 
                    readOnly={true}
                    type="text" 
                    name="type_of_audit" 
                    id="TypeOfAudit"
                    value={data.audit && data.audit.audit_plan ? data.audit.audit_plan.stage : ''}
                  />
                </FormGroup>

                <FormGroup>
                    <Label for="Standards">Standard(s)</Label>
                    <Input 
                    readOnly={true}
                    type="text" 
                    name="standard" 
                    id="standard"
                    value={data.audit && data.audit.audit_plan && data.audit.audit_plan.client && data.audit.audit_plan.client.certifications ? data.audit.audit_plan.client.certifications.standard : ''}
                  />
                    
                </FormGroup>

                <FormGroup>
                  <Label>Scope of certification</Label>
                  <Input 
                    readOnly={true}
                    type="text" 
                    name="scope_of_certification" 
                    id="ScopeOfCertification"
                    value={data.audit && data.audit.audit_plan && data.audit.audit_plan.client ? data.audit.audit_plan.client.scope_of_certification : ''}
                  />
                </FormGroup>

                <FormGroup>      
                  <Label>NACE Code(s)</Label>
                  <Input 
                    readOnly={true}
                    type="textarea" 
                    name="nace_codes" 
                    id="NaceCodes"
                    value={data.audit && data.audit.audit_plan && data.audit.audit_plan.client && data.audit.audit_plan.client.nace_codes ? data.audit.audit_plan.client.nace_codes   : ""} 
                  />
                </FormGroup>
            
                <FormGroup>
                  <Label for="Complexity">Complexity</Label>
                  <FormGroup>
                    <label className="fancy-radio">
                      <Input
                        type="radio"
                        name="complexity"
                        value="H"
                        checked={data.audit && data.audit.audit_plan && data.audit.audit_plan.client && data.audit.audit_plan.client.contract_review && data.audit.audit_plan.client.contract_review.complexity  === 'H'}
                        disabled={true}
                      />
                      <span>
                        <i></i> High
                      </span>
                    </label>

                    <label className="fancy-radio">
                      <Input
                        type="radio"
                        name="complexity"
                        value="M"
                        checked={data.audit && data.audit.audit_plan && data.audit.audit_plan.client && data.audit.audit_plan.client.contract_review && data.audit.audit_plan.client.contract_review.complexity  === 'M'}
                        disabled={true}

                      />
                      <span>
                        <i></i> Medium
                      </span>
                    </label>

                    <label className="fancy-radio">
                      <Input
                        type="radio"
                        name="complexity"
                        value="L"
                        checked={data.audit && data.audit.audit_plan && data.audit.audit_plan.client && data.audit.audit_plan.client.contract_review && data.audit.audit_plan.client.contract_review.complexity  === 'L'}
                        disabled={true}

                      />
                      <span>
                        <i></i> Low
                      </span>
                    </label>  
                  </FormGroup>
                </FormGroup>
                <Formik 
                    validateOnChange={false}
                    enableReinitialize={true}
                    initialValues={data}
                    onSubmit={async (values, { setSubmitting }) => {
                      //await sleep(500);
                      //alert(JSON.stringify(values, null, 2));

                      //panel 1 submit for review
                      if (values.action==="send_for_review_2" && values.technical_review_1_reviewed_at===null) {
                        values.technical_review_1_reviewed_at = moment().format('YYYY-MM-DD HH:mm:ss');
                      }

                      //panel 2 send for approval 
                      if(profile &&  profile.id == values.technical_review_reviewer_2_id && values.technical_review_2_reviewed_at===null){
                          values.action = "send_for_ceo_approval";
                          values.technical_review_2_reviewed_at = moment().format('YYYY-MM-DD HH:mm:ss');
                      }
                      if (props.approval) {
                        //CEO takes action
                        if(values.technical_review_1_approved_at === null) {
                            values.action = 'send_for_ed_approval';
                            values.technical_review_1_approved_at = moment().format('YYYY-MM-DD HH:mm:ss');
                        }
                        //ED takes action
                        if(values.technical_review_state === "5" && values.technical_review_2_approved_at === null) {
                            values.action = 'tr_approved';
                            values.technical_review_2_approved_at = moment().format('YYYY-MM-DD HH:mm:ss');
                        }
                      }
                      var data = objectToFormData(values, {indices: true, booleansAsIntegers: true});

                      let token = await localStorage.getItem('auth-token');

                      token = JSON.parse(token);

                      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

                      data.append('_method', 'PUT');

                      await api({
                        method: 'POST',
                        url: '/operation-reviews/' + id,
                        headers: {
                          'Content-Type': 'multipart/form-data',
                        },
                        data: data,
                      })

                      // await api.put('/operation-reviews/' + id, JSON.stringify(values), {
                      //   headers: {
                      //     'Content-Type': 'application/json',
                      //   },
                      // })
                      .then(function (response) {
                        addToast('CAP review successfully updated', { appearance: 'success' });
                        
                        history.push(props.path.replace(/:type/, type)  + '?refresh=' + Date.now());
                      })
                      .catch(function (error) {
                        if(error.message =='Network Error'){
                          
                          addToast('CAP review successfully updated', { appearance: 'success' });
                          history.push(props.path.replace(/:type/, type)  + '?refresh=' + Date.now());
                        }
                        else
                        {
                          addToast(error.message, { appearance: 'error' });
                        }
                      });
                    }}
                  >
                  {({ isSubmitting, values, setFieldValue, handleChange}) => (
                    <FormikForm>
                    <Card>  
                <Nav pills>    
                  <NavItem key='1'>
                    <NavLink 
                    className={classnames({ active: activeTab === '1'})} 
                    //style={{backgroundColor: 'bg-primary', textDecoration: 'none'}}
                    onClick={() => { 
                      toggle('1');
                    }}>
                      Granting Certification
                    </NavLink>
                  </NavItem>
                  <NavItem key='2'>
                    <NavLink 
                    className={classnames({ active: activeTab === '2' })}
                    //disabled={data.technical_review_1_reviewed_at===null}
                    onClick={() => { 
                      toggle('2');
                    }}>
                      Approval Certification
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1">
                    <FormGroup>
                        <Label for="">Assessment Date (dd/mm/yyyy)</Label>
                        <DateRangePicker 
                          singleDatePicker
                          containerStyles={{ display: "block" }}
                          drops="up"
                          startDate={values.tr_assessment_date && values.tr_assessment_date.length ? moment(values.tr_assessment_date).format('MM/DD/YYYY') : moment().format('MM/DD/YYYY')}
                          onApply={(event, picker) => {

                            setFieldValue('tr_assessment_date', picker.startDate.format('YYYY-MM-DD'));
                          }}
                        >
                          <Input 
                            disabled={readOnly}
                            autoComplete="off"
                            type="text"
                            name="tr_assessment_date"
                            id="tr_assessment_date"
                            value={values.tr_assessment_date ? moment(values.tr_assessment_date).format('DD/MM/YYYY') : null}
                            required={values.action==="save_draft" ? false:true}
                          />
                        </DateRangePicker>
                    </FormGroup>

                    <FormGroup>
                      <div>
                        <table className="table">
                          <thead className="thead-dark">
                            <tr>
                              <th>Granting Certification Form</th>
                              <th>Yes</th>
                              <th>No</th>
                              <th>N/A</th>
                              <th>Comment</th>
                            </tr>
                          </thead>
                          <tbody>
                            {[
                              {
                                field: "Fulfillment the contract review"
                              },
                              {
                                field: "Man days for initial assessment/surveillance shall be verified against the client’s activity/process"
                              },
                              {
                                field: "Number of sites shall be the same on Audit   Report"
                              },
                              {
                                field: "Standard shall be the same on  Application Certification Form, NIOSH Certification Audit Report and Audit Summary Report"
                              },
                              {
                                field: "Industry code assigned versus accredited scope"
                              },
                              {
                                field: "Is NIOSH Certification in scope for all accreditations offered?"
                              },
                              {
                                field: "Is there any NIOSH Certification Lead Auditor in the assessment team?"
                              },
                              {
                                field: "Is there a qualified team member for each allocated Code?"
                              },
                              {
                                field: "Is the assessment team qualified for the audited standard"
                              },
                              {
                                field: "Were fields fulfilled properly?"
                              },
                              {
                                field: "Number of sites shall be the same on Audit Summary Report and Summary of Audit Findings"
                              },
                            ].map((item, i) => 
                              <tr key={i}>
                                <td style={{ whiteSpace: "pre-wrap" }}>{item.field}{review===false?'false':null}</td>
                                <td>
                                  <label className="fancy-radio">
                                    <Input
                                      disabled={readOnly}
                                      type="radio"
                                      name={`granting_certification_form.${i}.value`}
                                      value="Y"
                                      onClick={() => {
                                        setFieldValue(`granting_certification_form.${i}.value`, 'Y')
                                      }}
                                      checked={values.granting_certification_form && values.granting_certification_form[`${i}`] ? values.granting_certification_form[`${i}`].value === 'Y' : ""}
                                      required={values.action==="save_draft"? false:true}
                                    />
                                    <span>
                                      <i></i>
                                    </span>
                                  </label>
                                </td>
                                <td>
                                  <label className="fancy-radio">
                                    <Input
                                      disabled={readOnly}
                                      type="radio"
                                      name={`granting_certification_form.${i}.value`}
                                      value="N"
                                      onClick={() => {
                                        setFieldValue(`granting_certification_form.${i}.value`, 'N')
                                      }}
                                      checked={values.granting_certification_form && values.granting_certification_form[`${i}`] ? values.granting_certification_form[`${i}`].value === 'N' : ""}
                                      required={values.action==="save_draft"? false:true}
                                    />
                                    <span>
                                      <i></i>
                                    </span>
                                  </label>
                                </td>
                                <td>
                                  <label className="fancy-radio">
                                    <Input
                                      disabled={readOnly}
                                      type="radio"
                                      name={`granting_certification_form.${i}.value`}
                                      value=""
                                      onClick={() => {
                                        setFieldValue(`granting_certification_form.${i}.value`, 'NA')
                                      }}
                                      checked={values.granting_certification_form && values.granting_certification_form[`${i}`] ? values.granting_certification_form[`${i}`].value === 'NA' : ""}
                                      required={values.action==="save_draft"? false:true}
                                    />
                                    <span>
                                      <i></i>
                                    </span>
                                  </label>
                                </td>
                                <td style={{ width: "50%" }}>
                                  <Input
                                    readOnly={readOnly}
                                    type="textarea"
                                    name={`granting_certification_form.${i}.comment`}
                                    value={values.granting_certification_form && values.granting_certification_form[`${i}`] ? values.granting_certification_form[`${i}`].comment : ""}
                                    onChange={handleChange}
                                    // onClick={() => {
                                    //   setFieldValue(`granting_certification_form.${i}.value`, 'N')
                                    // }}
                                    // checked={parseInt(values.documentation_implemented) === 1}
                                  />
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </FormGroup>

                    <FormGroup>
                      <div>
                        <table className="table">
                          <thead className="thead-dark">
                            <tr>
                              <th>Request For Certification</th>
                              <th>Yes</th>
                              <th>No</th>
                              <th>N/A</th>
                              <th>Comment</th>
                            </tr>
                          </thead>
                          <tbody>
                            {[
                              {
                                field: "Is the company name in accordance with Contract Review?"
                              },
                              {
                                field: "Are the scope and Industry codes in accordance to the Contract Review?"
                              },
                              {
                                field: "Were fields fulfilled properly?"
                              },
                            ].map((item, i) => 
                              <tr key={i}>
                                <td style={{ whiteSpace: "pre-wrap" }}>{item.field}</td>
                                <td>
                                  <label className="fancy-radio">
                                    <Input
                                      disabled={readOnly}
                                      type="radio"
                                      name={`request_for_certification.${i}.value`}
                                      value="Y"
                                      onClick={() => {
                                        setFieldValue(`request_for_certification.${i}.value`, 'Y')
                                      }}
                                      checked={values.request_for_certification && values.request_for_certification[`${i}`] ? values.request_for_certification[`${i}`].value === 'Y' : ""}
                                      required={values.action==="save_draft"? false:true}
                                    />
                                    <span>
                                      <i></i>
                                    </span>
                                  </label>
                                </td>
                                <td>
                                  <label className="fancy-radio">
                                    <Input
                                      disabled={readOnly}
                                      type="radio"
                                      name={`request_for_certification.${i}.value`}
                                      value="N"
                                      onClick={() => {
                                        setFieldValue(`request_for_certification.${i}.value`, 'N')
                                      }}
                                      checked={values.request_for_certification && values.request_for_certification[`${i}`] ? values.request_for_certification[`${i}`].value === 'N' : ""}
                                      required={values.action==="save_draft"? false:true}
                                    />
                                    <span>
                                      <i></i>
                                    </span>
                                  </label>
                                </td>
                                <td>
                                  <label className="fancy-radio">
                                    <Input
                                      disabled={readOnly}
                                      type="radio"
                                      name={`request_for_certification.${i}.value`}
                                      value=""
                                      onClick={() => {
                                        setFieldValue(`request_for_certification.${i}.value`, 'NA')
                                      }}
                                      checked={values.request_for_certification && values.request_for_certification[`${i}`] ? values.request_for_certification[`${i}`].value === 'NA' : ""}
                                      required={values.action==="save_draft"? false:true}
                                    />
                                    <span>
                                      <i></i>
                                    </span>
                                  </label>
                                </td>
                                <td style={{ width: "50%" }}>
                                  <Input
                                    readOnly={readOnly}
                                    type="textarea"
                                    name={`request_for_certification.${i}.comment`}
                                    value={values.request_for_certification && values.request_for_certification[`${i}`] ? values.request_for_certification[`${i}`].comment : ""}
                                    onChange={handleChange}
                                    // onClick={() => {
                                    //   setFieldValue(`granting_certification_form.${i}.value`, 'N')
                                    // }}
                                    // checked={parseInt(values.documentation_implemented) === 1}
                                  />
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </FormGroup>

                    <FormGroup>
                      <div>
                        <table className="table">
                          <thead className="thead-dark">
                            <tr>
                              <th>NIOSH Certification Audit Report</th>
                              <th>Yes</th>
                              <th>No</th>
                              <th>N/A</th>
                              <th>Comment</th>
                            </tr>
                          </thead>
                          <tbody>
                            {[
                              {
                                field: "Did company representative sign field “signed company representative” on Audit Report?"
                              },
                              {
                                field: "Were fields of NIOSH Certification Audit Report fulfilled properly?"
                              },
                            ].map((item, i) => 
                              <tr key={i}>
                                <td style={{ whiteSpace: "pre-wrap" }}>{item.field}</td>
                                <td>
                                  <label className="fancy-radio">
                                    <Input
                                      disabled={readOnly}
                                      type="radio"
                                      name={`niosh_certification_audit_report.${i}.value`}
                                      value="Y"
                                      onClick={() => {
                                        setFieldValue(`niosh_certification_audit_report.${i}.value`, 'Y')
                                      }}
                                      checked={values.niosh_certification_audit_report && values.niosh_certification_audit_report[`${i}`] ? values.niosh_certification_audit_report[`${i}`].value === 'Y' : ""}
                                      required={values.action==="save_draft"? false:true}
                                    />
                                    <span>
                                      <i></i>
                                    </span>
                                  </label>
                                </td>
                                <td>
                                  <label className="fancy-radio">
                                    <Input
                                      disabled={readOnly}
                                      type="radio"
                                      name={`niosh_certification_audit_report.${i}.value`}
                                      value="N"
                                      onClick={() => {
                                        setFieldValue(`niosh_certification_audit_report.${i}.value`, 'N')
                                      }}
                                      checked={values.niosh_certification_audit_report && values.niosh_certification_audit_report[`${i}`] ? values.niosh_certification_audit_report[`${i}`].value === 'N' : ""}
                                      required={values.action==="save_draft"? false:true}
                                    />
                                    <span>
                                      <i></i>
                                    </span>
                                  </label>
                                </td>
                                <td>
                                  <label className="fancy-radio">
                                    <Input
                                      disabled={readOnly}
                                      type="radio"
                                      name={`niosh_certification_audit_report.${i}.value`}
                                      value=""
                                      onClick={() => {
                                        setFieldValue(`niosh_certification_audit_report.${i}.value`, 'NA')
                                      }}
                                      checked={values.niosh_certification_audit_report && values.niosh_certification_audit_report[`${i}`] ? values.niosh_certification_audit_report[`${i}`].value === 'NA' : ""}
                                      required={values.action==="save_draft"? false:true}
                                    />
                                    <span>
                                      <i></i>
                                    </span>
                                  </label>
                                </td>
                                <td style={{ width: "50%" }}>
                                  <Input
                                    readOnly={readOnly}
                                    type="textarea"
                                    name={`niosh_certification_audit_report.${i}.comment`}
                                    value={values.niosh_certification_audit_report && values.niosh_certification_audit_report[`${i}`] ? values.niosh_certification_audit_report[`${i}`].comment : ""}
                                    onChange={handleChange}
                                    // onClick={() => {
                                    //   setFieldValue(`granting_certification_form.${i}.value`, 'N')
                                    // }}
                                    // checked={parseInt(values.documentation_implemented) === 1}
                                  />
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </FormGroup>

                    <FormGroup>
                      <div>
                        <table className="table">
                          <thead className="thead-dark">
                            <tr>
                              <th>Summary Of Audit Findings</th>
                              <th>Yes</th>
                              <th>No</th>
                              <th>N/A</th>
                              <th>Comment</th>
                            </tr>
                          </thead>
                          <tbody>
                            {[
                              {
                                field: "Does the total of minors and majors match the totals on Summary of Audit Findings?"
                              },
                              {
                                field: "If follow-up visit required, were fields fulfilled properly?"
                              },
                              {
                                field: "Is NIOSH Certification in scope for all accreditations offered on Summary of Audit Findings?"
                              },
                              {
                                field: "Are the addresses of Head Office and other sites (if applicable) available?"
                              },
                              {
                                field: "Did the Team Leader sign the recommendation for certification?"
                              },
                              {
                                field: "Were fields fulfilled properly?"
                              },
                            ].map((item, i) => 
                              <tr key={i}>
                                <td style={{ whiteSpace: "pre-wrap" }}>{item.field}</td>
                                <td>
                                  <label className="fancy-radio">
                                    <Input
                                      disabled={readOnly}
                                      type="radio"
                                      name={`summary_of_audit_findings.${i}.value`}
                                      value="Y"
                                      onClick={() => {
                                        setFieldValue(`summary_of_audit_findings.${i}.value`, 'Y')
                                      }}
                                      checked={values.summary_of_audit_findings && values.summary_of_audit_findings[`${i}`] ? values.summary_of_audit_findings[`${i}`].value === 'Y' : ""}
                                      required={values.action==="save_draft"? false:true}
                                    />
                                    <span>
                                      <i></i>
                                    </span>
                                  </label>
                                </td>
                                <td>
                                  <label className="fancy-radio">
                                    <Input
                                      disabled={readOnly}
                                      type="radio"
                                      name={`summary_of_audit_findings.${i}.value`}
                                      value="N"
                                      onClick={() => {
                                        setFieldValue(`summary_of_audit_findings.${i}.value`, 'N')
                                      }}
                                      checked={values.summary_of_audit_findings && values.summary_of_audit_findings[`${i}`] ? values.summary_of_audit_findings[`${i}`].value === 'N' : ""}
                                      required={values.action==="save_draft"? false:true}
                                    />
                                    <span>
                                      <i></i>
                                    </span>
                                  </label>
                                </td>
                                <td>
                                  <label className="fancy-radio">
                                    <Input
                                      disabled={readOnly}
                                      type="radio"
                                      name={`summary_of_audit_findings.${i}.value`}
                                      value=""
                                      onClick={() => {
                                        setFieldValue(`summary_of_audit_findings.${i}.value`, 'NA')
                                      }}
                                      checked={values.summary_of_audit_findings && values.summary_of_audit_findings[`${i}`] ? values.summary_of_audit_findings[`${i}`].value === 'NA' : ""}
                                      required={values.action==="save_draft"? false:true}
                                    />
                                    <span>
                                      <i></i>
                                    </span>
                                  </label>
                                </td>
                                <td style={{ width: "50%" }}>
                                  <Input
                                    readOnly={readOnly}
                                    type="textarea"
                                    name={`summary_of_audit_findings.${i}.comment`}
                                    value={values.summary_of_audit_findings && values.summary_of_audit_findings[`${i}`] ? values.summary_of_audit_findings[`${i}`].comment : ""}
                                    onChange={handleChange}
                                    // onClick={() => {
                                    //   setFieldValue(`granting_certification_form.${i}.value`, 'N')
                                    // }}
                                    // checked={parseInt(values.documentation_implemented) === 1}
                                  />
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </FormGroup>

                    <FormGroup>
                      <div>
                        <table className="table">
                          <thead className="thead-dark">
                            <tr>
                              <th>Audit Summary Report</th>
                              <th>Yes</th>
                              <th>No</th>
                              <th>N/A</th>
                              <th>Comment</th>
                            </tr>
                          </thead>
                          <tbody>
                            {[
                              {
                                field: "Did each team member issue an Audit Summary Report or were identified?"
                              },
                              {
                                field: "Are man-days described on Audit. Sum. Rep. in accordance with man days on Contract Review?"
                              },
                              {
                                field: "Were all requirements of the standard duly verified during the assessment and appropriate to the client’s scope?"
                              },
                              {
                                field: "Were all sites duly verified during the assessment?"
                              },
                              {
                                field: "Was auditor that covers the Quality Code the one who audited the Design and Production?"
                              },
                              {
                                field: "Was Audit Summary Report fulfilled properly? (included recommendation and conclusion)"
                              },
                            ].map((item, i) => 
                              <tr key={i}>
                                <td style={{ whiteSpace: "pre-wrap" }}>{item.field}</td>
                                <td>
                                  <label className="fancy-radio">
                                    <Input
                                      disabled={readOnly}
                                      type="radio"
                                      name={`audit_summary_report.${i}.value`}
                                      value="Y"
                                      onClick={() => {
                                        setFieldValue(`audit_summary_report.${i}.value`, 'Y')
                                      }}
                                      checked={values.audit_summary_report && values.audit_summary_report[`${i}`] ? values.audit_summary_report[`${i}`].value === 'Y' : ""}
                                      required={values.action==="save_draft"? false:true}
                                    />
                                    <span>
                                      <i></i>
                                    </span>
                                  </label>
                                </td>
                                <td>
                                  <label className="fancy-radio">
                                    <Input
                                      disabled={readOnly}
                                      type="radio"
                                      name={`audit_summary_report.${i}.value`}
                                      value="N"
                                      onClick={() => {
                                        setFieldValue(`audit_summary_report.${i}.value`, 'N')
                                      }}
                                      checked={values.audit_summary_report && values.audit_summary_report[`${i}`] ? values.audit_summary_report[`${i}`].value === 'N' : ""}
                                      required={values.action==="save_draft"? false:true}
                                    />
                                    <span>
                                      <i></i>
                                    </span>
                                  </label>
                                </td>
                                <td>
                                  <label className="fancy-radio">
                                    <Input
                                      disabled={readOnly}
                                      type="radio"
                                      name={`audit_summary_report.${i}.value`}
                                      value=""
                                      onClick={() => {
                                        setFieldValue(`audit_summary_report.${i}.value`, 'NA')
                                      }}
                                      checked={values.audit_summary_report && values.audit_summary_report[`${i}`] ? values.audit_summary_report[`${i}`].value === 'NA' : ""}
                                      required={values.action==="save_draft"? false:true}
                                    />
                                    <span>
                                      <i></i>
                                    </span>
                                  </label>
                                </td>
                                <td style={{ width: "50%" }}>
                                  <Input
                                    readOnly={readOnly}
                                    type="textarea"
                                    name={`audit_summary_report.${i}.comment`}
                                    value={values.audit_summary_report && values.audit_summary_report[`${i}`] ? values.audit_summary_report[`${i}`].comment : ""}
                                    onChange={handleChange}
                                    // onClick={() => {
                                    //   setFieldValue(`granting_certification_form.${i}.value`, 'N')
                                    // }}
                                    // checked={parseInt(values.documentation_implemented) === 1}
                                  />
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </FormGroup>

                    <FormGroup>
                      <div>
                        <table className="table">
                          <thead className="thead-dark">
                            <tr>
                              <th>NCR (Audit Finding)</th>
                              <th>Yes</th>
                              <th>No</th>
                              <th>N/A</th>
                              <th>Comment</th>
                            </tr>
                          </thead>
                          <tbody>
                            {[
                              {
                                field: "Is the category of Non Conformance defined appropriately?"
                              },
                              {
                                field: "Is the corrective action & root cause described?"
                              },
                              {
                                field: "Were all Non Conformance  accepted?"
                              },
                            ].map((item, i) => 
                              <tr key={i}>
                                <td style={{ whiteSpace: "pre-wrap" }}>{item.field}</td>
                                <td>
                                  <label className="fancy-radio">
                                    <Input
                                      disabled={readOnly}
                                      type="radio"
                                      name={`ncr_audit_finding.${i}.value`}
                                      value="Y"
                                      onClick={() => {
                                        setFieldValue(`ncr_audit_finding.${i}.value`, 'Y')
                                      }}
                                      checked={values.ncr_audit_finding && values.ncr_audit_finding[`${i}`] ? values.ncr_audit_finding[`${i}`].value === 'Y' : ""}
                                      required={values.action==="save_draft"? false:true}
                                    />
                                    <span>
                                      <i></i>
                                    </span>
                                  </label>
                                </td>
                                <td>
                                  <label className="fancy-radio">
                                    <Input
                                      disabled={readOnly}
                                      type="radio"
                                      name={`ncr_audit_finding.${i}.value`}
                                      value="N"
                                      onClick={() => {
                                        setFieldValue(`ncr_audit_finding.${i}.value`, 'N')
                                      }}
                                      checked={values.ncr_audit_finding && values.ncr_audit_finding[`${i}`] ? values.ncr_audit_finding[`${i}`].value === 'N' : ""}
                                      required={values.action==="save_draft"? false:true}
                                    />
                                    <span>
                                      <i></i>
                                    </span>
                                  </label>
                                </td>
                                <td>
                                  <label className="fancy-radio">
                                    <Input
                                      disabled={readOnly}
                                      type="radio"
                                      name={`ncr_audit_finding.${i}.value`}
                                      value=""
                                      onClick={() => {
                                        setFieldValue(`ncr_audit_finding.${i}.value`, 'NA')
                                      }}
                                      checked={values.ncr_audit_finding && values.ncr_audit_finding[`${i}`] ? values.ncr_audit_finding[`${i}`].value === 'NA' : ""}
                                      required={values.action==="save_draft"? false:true}
                                    />
                                    <span>
                                      <i></i>
                                    </span>
                                  </label>
                                </td>
                                <td style={{ width: "50%" }}>
                                  <Input
                                    readOnly={readOnly}
                                    type="textarea"
                                    name={`ncr_audit_finding.${i}.comment`}
                                    value={values.ncr_audit_finding && values.ncr_audit_finding[`${i}`] ? values.ncr_audit_finding[`${i}`].comment : ""}
                                    onChange={handleChange}
                                    // onClick={() => {
                                    //   setFieldValue(`granting_certification_form.${i}.value`, 'N')
                                    // }}
                                    // checked={parseInt(values.documentation_implemented) === 1}
                                  />
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </FormGroup>

                    <FormGroup>
                        <div>
                        <table className="table">
                          <thead className="thead-dark">
                            <tr>
                              <th>Audit Note</th>
                              <th>Yes</th>
                              <th>No</th>
                              <th>N/A</th>
                              <th>Comment</th>
                            </tr>
                          </thead>
                          <tbody>
                            {[
                              {
                                field: "Is there an Audit Note available?"
                              },
                              {
                                field: "Is there any reference to the codes, regulatory/statutory requirements?"
                              },
                              {
                                field: "Were fields fulfilled properly?"
                              },
                            ].map((item, i) => 
                              <tr key={i}>
                                <td style={{ whiteSpace: "pre-wrap" }}>{item.field}</td>
                                <td>
                                  <label className="fancy-radio">
                                    <Input
                                      disabled={readOnly}
                                      type="radio"
                                      name={`audit_note.${i}.value`}
                                      value="Y"
                                      onClick={() => {
                                        setFieldValue(`audit_note.${i}.value`, 'Y')
                                      }}
                                      checked={values.audit_note && values.audit_note[`${i}`] ? values.audit_note[`${i}`].value === 'Y' : ""}
                                      required={values.action==="save_draft"? false:true}
                                    />
                                    <span>
                                      <i></i>
                                    </span>
                                  </label>
                                </td>
                                <td>
                                  <label className="fancy-radio">
                                    <Input
                                      disabled={readOnly}
                                      type="radio"
                                      name={`audit_note.${i}.value`}
                                      value="N"
                                      onClick={() => {
                                        setFieldValue(`audit_note.${i}.value`, 'N')
                                      }}
                                      checked={values.audit_note && values.audit_note[`${i}`] ? values.audit_note[`${i}`].value === 'N' : ""}
                                      required={values.action==="save_draft"? false:true}
                                    />
                                    <span>
                                      <i></i>
                                    </span>
                                  </label>
                                </td>
                                <td>
                                  <label className="fancy-radio">
                                    <Input
                                      disabled={readOnly}
                                      type="radio"
                                      name={`audit_note.${i}.value`}
                                      value=""
                                      onClick={() => {
                                        setFieldValue(`audit_note.${i}.value`, 'NA')
                                      }}
                                      checked={values.audit_note && values.audit_note[`${i}`] ? values.audit_note[`${i}`].value === 'NA' : ""}
                                      required={values.action==="save_draft"? false:true}
                                    />
                                    <span>
                                      <i></i>
                                    </span>
                                  </label>
                                </td>
                                <td style={{ width: "50%" }}>
                                  <Input
                                    readOnly={readOnly}
                                    type="textarea"
                                    name={`audit_note.${i}.comment`}
                                    value={values.audit_note && values.audit_note[`${i}`] ? values.audit_note[`${i}`].comment : ""}
                                    onChange={handleChange}
                                    // onClick={() => {
                                    //   setFieldValue(`granting_certification_form.${i}.value`, 'N')
                                    // }}
                                    // checked={parseInt(values.documentation_implemented) === 1}
                                  />
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                      </FormGroup>
                    
                    { type.toUpperCase() === 'MSPO' || type.toUpperCase() === 'MSPO-SCCS' ?                         
                    <FormGroup>
                      <div>
                        <table className="table">
                          <thead className="thead-dark">
                            <tr>
                              <th>Panel Peer Reviewer</th>
                              <th>Yes</th>
                              <th>No</th>
                              <th>N/A</th>
                              <th>Comment</th>
                            </tr>
                          </thead>
                          <tbody>
                            {[
                              {
                                field: "Is there two Peer Reviewer been identified?           "     
                              },
                              {
                                field: "Is the all comment from Reviewer been rectified?                  "     
                              },
                              {
                                field: "Were fields fulfilled properly?                       "     
                              },
                            ].map((item, i) => 
                              <tr key={i}>
                                <td style={{ whiteSpace: "pre-wrap" }}>{item.field}</td>
                                <td>
                                  <label className="fancy-radio">
                                    <Input
                                      disabled={readOnly}
                                      type="radio"
                                      name={`panel_peer_reviewer.${i}.value`}
                                      value="Y"
                                      onClick={() => {
                                        setFieldValue(`panel_peer_reviewer.${i}.value`, 'Y')
                                      }}
                                      checked={values.panel_peer_reviewer && values.panel_peer_reviewer[`${i}`] ? values.panel_peer_reviewer[`${i}`].value === 'Y' : ""}
                                      required={values.action==="save_draft"? false:true}
                                    />
                                    <span>
                                      <i></i>
                                    </span>
                                  </label>
                                </td>
                                <td>
                                  <label className="fancy-radio">
                                    <Input
                                      disabled={readOnly}
                                      type="radio"
                                      name={`panel_peer_reviewer.${i}.value`}
                                      value="N"
                                      onClick={() => {
                                        setFieldValue(`panel_peer_reviewer.${i}.value`, 'N')
                                      }}
                                      checked={values.panel_peer_reviewer && values.panel_peer_reviewer[`${i}`] ? values.panel_peer_reviewer[`${i}`].value === 'N' : ""}
                                      required={values.action==="save_draft"? false:true}
                                    />
                                    <span>
                                      <i></i>
                                    </span>
                                  </label>
                                </td>
                                <td>
                                  <label className="fancy-radio">
                                    <Input
                                      disabled={readOnly}
                                      type="radio"
                                      name={`panel_peer_reviewer.${i}.value`}
                                      value=""
                                      onClick={() => {
                                        setFieldValue(`panel_peer_reviewer.${i}.value`, 'NA')
                                      }}
                                      checked={values.panel_peer_reviewer && values.panel_peer_reviewer[`${i}`] ? values.panel_peer_reviewer[`${i}`].value === 'NA' : ""}
                                      required={values.action==="save_draft"? false:true}
                                    />
                                    <span>
                                      <i></i>
                                    </span>
                                  </label>
                                </td>
                                <td style={{ width: "50%" }}>
                                  <Input
                                    readOnly={readOnly}
                                    type="textarea"
                                    name={`panel_peer_reviewer.${i}.comment`}
                                    value={values.panel_peer_reviewer && values.panel_peer_reviewer[`${i}`] ? values.panel_peer_reviewer[`${i}`].comment : ""}
                                    onChange={handleChange}
                                    // onClick={() => {
                                    //   setFieldValue(`granting_certification_form.${i}.value`, 'N')
                                    // }}
                                    // checked={parseInt(values.documentation_implemented) === 1}
                                  />
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </FormGroup>
                    :""}

                    <FormGroup>
                      <div>
                        <table className="table">
                          <thead className="thead-dark">
                            <tr>
                              <th>Audit Plan</th>
                              <th>Yes</th>
                              <th>No</th>
                              <th>N/A</th>
                              <th>Comment</th>
                            </tr>
                          </thead>
                          <tbody>
                            {[
                              {
                                field: "Stage 1 Audit"
                              },
                              {
                                field: "Stage 2 Audit"
                              },
                              {
                                field: "Surveillance Audit"
                              },
                              {
                                field: "Recertification Audit"
                              },
                              {
                                field: "Were surveillance visits according to Contract Review?"
                              },
                              {
                                field: "If multi-sited, was HO indicated in all surveillance visits?"
                              },
                              {
                                field: "Is there any correlation between sites and items to be addressed?"
                              },
                              {
                                field: "Were fields fulfilled properly?"
                              },
                            ].map((item, i) => 
                              <tr key={i}>
                                <td style={{ whiteSpace: "pre-wrap" }}>{item.field}</td>
                                <td>
                                  <label className="fancy-radio">
                                    <Input
                                      disabled={readOnly}
                                      type="radio"
                                      name={`audit_plan.${i}.value`}
                                      value="Y"
                                      onClick={() => {
                                        setFieldValue(`audit_plan.${i}.value`, 'Y')
                                      }}
                                      checked={values.audit_plan && values.audit_plan[`${i}`] ? values.audit_plan[`${i}`].value === 'Y' : ""}
                                      required={values.action==="save_draft"? false:true}
                                    />
                                    <span>
                                      <i></i>
                                    </span>
                                  </label>
                                </td>
                                <td>
                                  <label className="fancy-radio">
                                    <Input
                                      disabled={readOnly}
                                      type="radio"
                                      name={`audit_plan.${i}.value`}
                                      value="N"
                                      onClick={() => {
                                        setFieldValue(`audit_plan.${i}.value`, 'N')
                                      }}
                                      checked={values.audit_plan && values.audit_plan[`${i}`] ? values.audit_plan[`${i}`].value === 'N' : ""}
                                      required={values.action==="save_draft"? false:true}
                                    />
                                    <span>
                                      <i></i>
                                    </span>
                                  </label>
                                </td>
                                <td>
                                  <label className="fancy-radio">
                                    <Input
                                      disabled={readOnly}
                                      type="radio"
                                      name={`audit_plan.${i}.value`}
                                      value=""
                                      onClick={() => {
                                        setFieldValue(`audit_plan.${i}.value`, 'NA')
                                      }}
                                      checked={values.audit_plan && values.audit_plan[`${i}`] ? values.audit_plan[`${i}`].value === 'NA' : ""}
                                      required={values.action==="save_draft"? false:true}
                                    />
                                    <span>
                                      <i></i>
                                    </span>
                                  </label>
                                </td>
                                <td style={{ width: "50%" }}>
                                  <Input
                                    readOnly={readOnly}
                                    type="textarea"
                                    name={`audit_plan.${i}.comment`}
                                    value={values.audit_plan && values.audit_plan[`${i}`] ? values.audit_plan[`${i}`].comment : ""}
                                    onChange={handleChange}
                                    // onClick={() => {
                                    //   setFieldValue(`granting_certification_form.${i}.value`, 'N')
                                    // }}
                                    // checked={parseInt(values.documentation_implemented) === 1}
                                  />
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </FormGroup>

                    <FormGroup>
                      <div>
                        <table className="table">
                          <thead className="thead-dark">
                            <tr>
                              <th>Others</th>
                              <th>Yes</th>
                              <th>No</th>
                              <th>N/A</th>
                              <th>Comment</th>
                            </tr>
                          </thead>
                          <tbody>
                            {[
                              {
                                field: "Is old certificate copy available? (exception Initial/ Main Audit)"
                              },
                              {
                                field: "Any other relevant information (eg. Public information, comments on the audit report from the client, etc)"
                              },
                            ].map((item, i) => 
                              <tr key={i}>
                                <td style={{ whiteSpace: "pre-wrap" }}>{item.field}</td>
                                <td>
                                  <label className="fancy-radio">
                                    <Input
                                      disabled={readOnly}
                                      type="radio"
                                      name={`others.${i}.value`}
                                      value="Y"
                                      onClick={() => {
                                        setFieldValue(`others.${i}.value`, 'Y')
                                      }}
                                      checked={values.others && values.others[`${i}`] ? values.others[`${i}`].value === 'Y' : ""}
                                    />
                                    <span>
                                      <i></i>
                                    </span>
                                  </label>
                                </td>
                                <td>
                                  <label className="fancy-radio">
                                    <Input
                                      disabled={readOnly}
                                      type="radio"
                                      name={`others.${i}.value`}
                                      value="N"
                                      onClick={() => {
                                        setFieldValue(`others.${i}.value`, 'N')
                                      }}
                                      checked={values.others && values.others[`${i}`] ? values.others[`${i}`].value === 'N' : ""}
                                      required={values.action==="save_draft"? false:true}
                                    />
                                    <span>
                                      <i></i>
                                    </span>
                                  </label>
                                </td>
                                <td>
                                  <label className="fancy-radio">
                                    <Input
                                      disabled={readOnly}
                                      type="radio"
                                      name={`others.${i}.value`}
                                      value=""
                                      onClick={() => {
                                        setFieldValue(`others.${i}.value`, 'NA')
                                      }}
                                      checked={values.others && values.others[`${i}`] ? values.others[`${i}`].value === 'NA' : ""}
                                      required={values.action==="save_draft"? false:true}
                                    />
                                    <span>
                                      <i></i>
                                    </span>
                                  </label>
                                </td>
                                <td style={{ width: "50%" }}>
                                  <Input
                                    readOnly={readOnly}
                                    type="textarea"
                                    name={`others.${i}.comment`}
                                    value={values.others && values.others[`${i}`] ? values.others[`${i}`].comment : ""}
                                    onChange={handleChange}
                                    // onClick={() => {
                                    //   setFieldValue(`granting_certification_form.${i}.value`, 'N')
                                    // }}
                                    // checked={parseInt(values.documentation_implemented) === 1}
                                  />
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </FormGroup>

                    <FormGroup>
                      <div>
                        <table className="table">
                          <thead className="thead-dark">
                            <tr>
                              <th>Decision For Granting Certification</th>
                              <th>Yes</th>
                              <th>No</th>
                              <th>N/A</th>
                              <th>Comment</th>
                            </tr>
                          </thead>
                          <tbody>
                            {[
                              {
                                field: "Is all above requirement satisfactorily fulfilled?"
                              },
                              {
                                field: "Panel is agree to approve the granting certification?               "
                              },
                            ].map((item, i) => 
                              <tr key={i}>
                                <td style={{ whiteSpace: "pre-wrap" }}>{item.field}</td>
                                <td>
                                  <label className="fancy-radio">
                                    <Input
                                      disabled={readOnly}
                                      type="radio"
                                      name={`decision_for_granting_certification.${i}.value`}
                                      value="Y"
                                      onClick={() => {
                                        setFieldValue(`decision_for_granting_certification.${i}.value`, 'Y')
                                      }}
                                      checked={values.decision_for_granting_certification && values.decision_for_granting_certification[`${i}`] ? values.decision_for_granting_certification[`${i}`].value === 'Y' : ""}
                                      required={values.action==="save_draft"? false:true}
                                    />
                                    <span>
                                      <i></i>
                                    </span>
                                  </label>
                                </td>
                                <td>
                                  <label className="fancy-radio">
                                    <Input
                                      disabled={readOnly}
                                      type="radio"
                                      name={`decision_for_granting_certification.${i}.value`}
                                      value="N"
                                      onClick={() => {
                                        setFieldValue(`decision_for_granting_certification.${i}.value`, 'N')
                                      }}
                                      checked={values.decision_for_granting_certification && values.decision_for_granting_certification[`${i}`] ? values.decision_for_granting_certification[`${i}`].value === 'N' : ""}
                                      required={values.action==="save_draft"? false:true}
                                    />
                                    <span>
                                      <i></i>
                                    </span>
                                  </label>
                                </td>
                                <td>
                                  <label className="fancy-radio">
                                    <Input
                                      disabled={readOnly}
                                      type="radio"
                                      name={`decision_for_granting_certification.${i}.value`}
                                      value=""
                                      onClick={() => {
                                        setFieldValue(`decision_for_granting_certification.${i}.value`, 'NA')
                                      }}
                                      checked={values.decision_for_granting_certification && values.decision_for_granting_certification[`${i}`] ? values.decision_for_granting_certification[`${i}`].value === 'NA' : ""}
                                      required={values.action==="save_draft"? false:true}
                                    />
                                    <span>
                                      <i></i>
                                    </span>
                                  </label>
                                </td>
                                <td style={{ width: "50%" }}>
                                  <Input
                                    readOnly={readOnly}
                                    type="textarea"
                                    name={`decision_for_granting_certification.${i}.comment`}
                                    value={values.decision_for_granting_certification && values.decision_for_granting_certification[`${i}`] ? values.decision_for_granting_certification[`${i}`].comment : ""}
                                    onChange={handleChange}
                                    // onClick={() => {
                                    //   setFieldValue(`granting_certification_form.${i}.value`, 'N')
                                    // }}
                                    // checked={parseInt(values.documentation_implemented) === 1}
                                  />
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </FormGroup>

                    <FormGroup>
                      <Label for="">Comments - CAP</Label>
                      <Editor 
                        // key={note.id}
                        disabled={readOnly}
                        apiKey={process.env.REACT_APP_TINYMCE_KEY}
                        value={values.comments_cap}
                        init={{
                          height: 200,
                          menubar: 'file edit view insert format tools table tc help',
                          plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount'
                          ],
                          toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media pageembed template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment',
                          paste_data_images: true,
                          content_style: "body { font-family: Arial; font-size: 10pt; }",
                        }}
                        onEditorChange={(content) => {
                          setFieldValue('comments_cap', content);
                        }}
                      />
                    </FormGroup>
                  </TabPane>

                  <TabPane tabId="2">
                  
                    <FormGroup>
                    <div>
                      <table className="table">
                        <thead className="thead-dark">
                          <tr>
                            <th>Change To Certification And Reason For Change</th>
                            <th>Yes</th>
                            <th>No</th>
                          </tr>
                        </thead>
                        <tbody>
                          {[
                            {
                              field: "Change of Address"
                            },
                            {
                              field: "Change of Name"
                            },
                            {
                              field: "Upgrade Standard(s)"
                            }
                          ].map((item, i) => 
                            <tr key={i}>
                              <td style={{ whiteSpace: "pre-wrap" }}>{item.field}</td>
                              
                              <td>
                                <label className="fancy-radio">
                                  <Input
                                    disabled={readOnly}
                                    type="radio"
                                    name={`approval_certification_form.${i}.value`}
                                    value="Y"
                                    onClick={() => {
                                      setFieldValue(`approval_certification_form.${i}.value`, 'Y')
                                    }}
                                    checked={values.approval_certification_form && values.approval_certification_form[`${i}`] ? values.approval_certification_form[`${i}`].value === 'Y' : ""}
                                    required={values.action==="save_draft"? false:true}
                                  />
                                  <span>
                                    <i></i>
                                  </span>
                                </label>
                              </td>
                              
                              <td>
                                <label className="fancy-radio">
                                  <Input
                                    disabled={readOnly}
                                    type="radio"
                                    name={`approval_certification_form.${i}.value`}
                                    value="N"
                                    onClick={() => {
                                      setFieldValue(`approval_certification_form.${i}.value`, 'N')
                                    }}
                                    checked={values.approval_certification_form && values.approval_certification_form[`${i}`] ? values.approval_certification_form[`${i}`].value === 'N' : ""}
                                    required={values.action==="save_draft"? false:true}                                  />
                                  <span>
                                    <i></i>
                                  </span>
                                </label> 
                              </td>
                            </tr>
                          )}
                            {values.approval_certification_form && values.approval_certification_form[2] && values.approval_certification_form[2].value === 'Y' ? 
                          <tr>
                            <td colSpan="3" style={{ whiteSpace: "pre-wrap" }}>
                              {values.approval_certification_form && values.approval_certification_form[2] && values.approval_certification_form[2].value === 'Y' ? 
                                [{
                                  field: "MS1722"
                                },
                                {
                                  field: "OHSAS 18001"
                                },
                                {
                                  field: "ISO 9001"
                                },
                                {
                                  field: "ISO 14001"
                                },
                                {
                                  field: "ISO 22000"
                                },
                                {
                                  field: "MS 2530-3"
                                },
                                {
                                  field: "ISO 45001"
                                }
                              ].map((item, x) =>  
                                <React.Fragment key={x}>
                                  {review || approval ?
                                 
                                  values.approval_certification_form[2] && values.approval_certification_form[2].standard ? values.approval_certification_form[2].standard.map((st, k) => <span>{st==item.field ? st : null} </span>) : null
                                  
                                  :
                                <label class="control-inline fancy-checkbox">
                                  <Input 
                                    disabled={readOnly}
                                    type="checkbox" 
                                    checked={values.approval_certification_form && values.approval_certification_form[2] && values.approval_certification_form[2].standard && values.approval_certification_form[2].standard[`${x}`] && values.approval_certification_form[2].standard[`${x}`]===item.field ? values.approval_certification_form[2].standard[`${x}`] : null} 
                                    value = {item.field}
                                    onClick={(e) => {
                                      //setFieldValue(`approval_certification_form.2.standard[.${i}.]`, 
                                      //e.target.value)
                                      setFieldValue(e.target.name,  e.target.checked)
                                    }}
                                  />
                                  <span>
                                    {item.field}
                                  </span>
                                </label>
                                  }
                              </React.Fragment>
                                ) : ""} 
                            </td>
                          </tr>
                          : ""}
                        </tbody>
                      </table>
                    </div>
                    </FormGroup>

                    <FormGroup>
                      <Label for="">CAP Declaration</Label>
                      <div className="row clearfix">
                        <div className="col-lg-1">
                          <div className="fancy-checkbox">
                            <label>
                              <Input 
                                //disabled={readOnly}
                                disabled={values.action == "send_for_review_2" || isSubmitting || readOnly}
                                type="checkbox" 
                                value='1'
                                name="cap_declaration"
                                checked={values.cap_declaration && values.cap_declaration===1 ? values.cap_declaration : null} 
                                onChange={(e) => {
                                  setFieldValue(e.target.name, e.target.checked);
                                }}
                                /* onChange={(checked) => {
                                  setFieldValue(`cap_declaration`, checked === true ? 'true' : 'false')
                                }} */
                              />
                              <span>

                              </span>
                            </label>
                          </div>                                          
                        </div>
                        <div className="col-lg-11">
                        I declare that this audit was performed in accordance with the requirements of the NIOSH Certification Management System, and that the Team Leader has ensured that the Management Systems applicable have been reviewed. All the non-conformities have been cleared and where necessary translated into English. All documentation as required by the NIOSH Certification Management System is present and correct. I have fully reviewed the Team Leader’s report and agree with the recommendation for certification. The Client file structure is as specified within the NIOSH Certification Management System and is available for audit when requested.
                        </div>
                      </div>
                    </FormGroup>
                      <br></br>
                      {values.technical_review_1_approved_at !== null?
                        <blockquote><p class="blockquote blockquote-info">
                        <small>
                        CEO approved on <b>{ 
                          moment(values.technical_review_1_approved_at).format('DD/MM/YYYY')
                          }</b>
                        </small>
                        </p>
                        </blockquote>
                        :null}  
                        {values.technical_review_2_approved_at !== null?
                          <blockquote><p class="blockquote blockquote-info">
                          <small>
                            Chairman approved on <b>{ 
                          moment(values.technical_review_2_approved_at).format('DD/MM/YYYY')
                          }</b>
                          </small>
                          </p>
                          </blockquote>
                        :null}   

                        {view /* || review  */|| approval  || profile && values.technical_review_reviewer_1_id != profile.auditor_id || values.technical_review_2_approved_at !== null ? 
                          null
                        :
                          <div className="row clearfix">
                        <div className="col-sm-4">
                        <React.Fragment>
                        <label className="fancy-radio">
                          <Input
                            //disabled={readOnly}
                            type="radio"
                            name="action"
                            value="save_draft"
                            onClick={handleChange}
                            checked={values.action === 'save_draft' }
                          />
                          <span>
                            <i></i> Save Draft
                          </span>
                        </label>
                        <br />

                        <label className="fancy-radio">
                          <Input
                            disabled={!values.cap_declaration || isSubmitting}
                           // disabled={readOnly}
                            type="radio"
                            name="action"
                            value="send_for_review_2"
                            onClick={handleChange}
                            checked={values.action === 'send_for_review_2'}
                          />
                          <span>
                            <i></i> Send for Review
                          </span>
                        </label> 
                        <br />

                        </React.Fragment>
                          <button 
                            disabled={isSubmitting }
                            type="submit" 
                            className="btn btn-primary"
                          >
                            {isSubmitting ? (values.action === 'send_for_review_2' ? 'Sending For Review...' : 'Saving...') : (values.action === 'send_for_review_2' ? 'Send For Review #2': 'Save')}
                          </button>
                          &nbsp;
                        <Button 
                          // className="btn btn-outline-secondary"
                          outline
                          color="secondary" 
                          tag={Link} 
                          to={props.path.replace(/:type/, type)}
                        >
                          Cancel
                        </Button> 
                        </div>
                          </div> 
                        }

                        {!view && values.technical_review_state > 3 && profile && profile.permissions && profile.permissions.find(o => o.name.toLowerCase() === 'technical_review.amend') ?
                          <div className="row clearfix">
                            <div className="col-sm-12">
                              <FormGroup>      
                              <Label>Amendment has been made. Please fill up changes of the data</Label>
                              <Input 
                               // readOnly={true}
                                type="textarea" 
                                name="amendment_note" 
                                id="AmendementNote"
                                placeholder="1. Date Amendment: <date>,  Amended by whom: <name>, Reason: <item>
                                "
                                onChange={handleChange}
                                value={values.amendment_note ?  values.amendment_note  : ""} 
                              />
                              </FormGroup>
                            </div>
                            <div className="col-sm-4">
                              <button 
                                disabled={isSubmitting }
                                type="submit" 
                                className="btn btn-primary"
                              >
                                {isSubmitting ? 'Saving...': 'Save the changes'}
                              </button>
                              &nbsp;
                              <Button 
                                // className="btn btn-outline-secondary"
                                outline
                                color="secondary" 
                                tag={Link} 
                                to={props.path.replace(/:type/, type)}
                              >
                              Cancel
                              </Button> 
                            </div>
                          </div> 
                        : null
                        }

                        {review && values.technical_review_state >= 3 ?
                          <div className="row clearfix">
                            <div className="col-sm-4">
                              <button 
                                disabled={isSubmitting}
                                type="submit" 
                                className="btn btn-primary"
                              >
                                {isSubmitting && values.action === 'send_for_ceo_approval' ? 'Sending For Approval...' : 'Reviewed & Send for CEO Approval'}
                              </button>
                              &nbsp;
                              <Button 
                                // className="btn btn-outline-secondary"
                                outline
                                color="secondary" 
                                tag={Link} 
                                to={props.path.replace(/:type/, type)}
                              >
                                Cancel
                              </Button> 
                            </div>
                          </div> 
                        : null}

                        {view ?
                          <div className="row clearfix">
                            <div className="col-sm-4">
                            <Button 
                              // className="btn btn-outline-secondary"
                              outline
                              color="secondary" 
                              tag={Link} 
                              to={props.path.replace(/:type/, type)}
                            >
                              Cancel
                            </Button> 
                            </div>
                          </div> 
                        : null}

                        {approval && values.technical_review_state === '4' && profile.id===values.technical_review_approver_1_id  && values.technical_review_1_approved_at === null ?
                          <div className="row clearfix">
                            <div className="col-sm-4">
                              <button 
                                disabled={isSubmitting}
                                type="submit" 
                                className="btn btn-primary"
                              >
                              {isSubmitting ? 'Approving...' : 'Approve by CEO'}
                              </button>
                              &nbsp;
                              <Button 
                                // className="btn btn-outline-secondary"
                                outline
                                color="secondary" 
                                tag={Link} 
                                to={props.path.replace(/:type/, type)}
                              >
                                Cancel
                              </Button> 
                            </div>
                          </div> 
                        : null}           

                        {approval && values.technical_review_state === '5' && profile.id===values.technical_review_approver_2_id  && values.technical_review_2_approved_at === null ?
                          <div className="row clearfix">
                            <div className="col-sm-4">
                              <button 
                                disabled={isSubmitting}
                                type="submit" 
                                className="btn btn-primary"
                              >
                              {isSubmitting ? 'Approving...' : 'Approve by Chairman'}
                              </button>
                              &nbsp;
                              <Button 
                                // className="btn btn-outline-secondary"
                                outline
                                color="secondary" 
                                tag={Link} 
                                to={props.path.replace(/:type/, type)}
                              >
                                Cancel
                              </Button> 
                            </div>
                          </div> 
                        : null}           
                  </TabPane>
                </TabContent>
                </Card>
                </FormikForm>
                )}
                </Formik>
              </div>
            </Card>
            </div>
          </div>
      </div>
    </React.Fragment>                 
    )
}

export default Form;